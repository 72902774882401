import { formatData } from "./../service/table-data";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { TableItemProps } from "@/service/table-data";
import { getInventoryByFilter } from "@/api/ts/inventory";

export type FormState = Ref<Record<string, string>>;
interface AppStoreProps {
  dataItems: TableItemProps[];
  filteredDataItems: TableItemProps[];
  initialTableCols: Ref<object[]>;
  favoriteItems: TableItemProps[];
  searchInput: Ref<string>;
  sidebarFilterKey: Ref<string>;
  isShowSidebar: Ref<boolean>;
}

export const useAppStore = defineStore("appStore", {
  state: (): AppStoreProps => ({
    dataItems: [],
    filteredDataItems: [],
    initialTableCols: ref([]),
    favoriteItems: [],
    searchInput: ref(""),
    sidebarFilterKey: ref(""),
    isShowSidebar: ref(true),
  }),
  actions: {
    setInitialTableItems(payload: any) {
      const dataFormatted = formatData(payload);
      this.filteredDataItems = dataFormatted;
      this.dataItems = dataFormatted;
    },
    validateStatus(payload) {
      const validStatuses = [
        "Ativo",
        "Pendente",
        "Arquivado",
        "Avaliar",
        "Revisar",
        "Corrigir",
      ];
      return validStatuses.includes(payload);
    },
    setTableFilter(
      status?: string | null | { value: string },
      filter?: string | null,
      isFavorite: boolean = false
    ) {
      // Normaliza os valores recebidos
      const normalizedStatus =
        status && typeof status === "object" && "value" in status
          ? status.value.toLowerCase()
          : typeof status === "string"
          ? status.toLowerCase()
          : null;

      const normalizedFiltersStatus =
        typeof filter === "string" ? filter.toLowerCase() : null;

      // Filtra os itens combinando os filtros selecionados
      this.filteredDataItems = this.dataItems.filter((item) => {
        const matchesStatus = normalizedStatus
          ? item.state.toLowerCase() === normalizedStatus
          : true;

        const matchesFiltersStatus = normalizedFiltersStatus
          ? item.state.toLowerCase() === normalizedFiltersStatus
          : true;

        const matchesFavorite = isFavorite
          ? this.favoriteItems.some((favorite) => favorite.Id === item.Id)
          : true;

        // Retorna apenas itens que atendem a todos os critérios
        return matchesStatus && matchesFiltersStatus && matchesFavorite;
      });
    },

    setTableCustomFilter(payload: any) {
      this.filterState = payload;
      getInventoryByFilter(payload)
        .then((filteredData) => {
          this.filteredDataItems = formatData(filteredData);
        })
        .catch((error) => {
          console.error("Erro ao buscar dados filtrados:", error);
        });
    },
    resetCustomFilter() {
      this.filterState = null;
      this.filteredDataItems = this.dataItems;
    },
    addRowToFavorite(payload: TableItemProps) {
      const alreadyFavorited = this.favoriteItems.some(
        (item) => item.Id === payload.Id
      );
      if (!alreadyFavorited) {
        this.favoriteItems.push(payload);
      }
    },
    removeRowFromFavorite(payload: any) {
      const index = this.favoriteItems.findIndex(
        (item) => item.Id === payload.Id
      );
      if (index !== -1) {
        this.favoriteItems.splice(index, 1);
      }
    },
    setSearchInput(payload: string) {
      this.searchInput = payload;
    },
    setSidebarFilterKey(payload: string) {
      this.sidebarFilterKey = payload;
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
  },
});
