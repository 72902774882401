import { gql } from "graphql-tag";

export const GET_ALL_INVENTORY = gql`
  query getAllInventory {
    data_mapping_treatment_activity {
      created_at
      description
      id
      name_activity
      status
      user_responsible {
        displayName
        id
      }
      owner {
        id
        displayName
      }
      area_modules {
        area {
          description
        }
        id_module
      }
      risk_treatment_activities {
        id_treatment_activity
        risk {
          initial_risk
          risk_monitoring(limit: 1, order_by: { created_at: desc }) {
            residual_risk
          }
        }
      }
      assessments {
        supplier_controller {
          supplier {
            fantasy_name
            corporate_reason
          }
        }
        supplier_controller_set {
          supplier {
            corporate_reason
            fantasy_name
          }
        }
        supplier_operator {
          supplier {
            fantasy_name
            corporate_reason
          }
        }
        combo_data_legal_base {
          value
          id
        }
      }
      category_holders {
        combo_data_category_holders {
          value
          id
        }
      }
      profile_holders {
        combo_data {
          value
          id
        }
      }
      volume_of_holders
    }
    data_mapping_treatment_activity_temporary {
      id
      user {
        id
        displayName
        email
      }
      created_at
      values
    }
  }
`;
export const GET_INVENTORY_BY_ID = gql`
  query getInventoryById($id: Int!) {
    data_mapping_treatment_activity_by_pk(id: $id) {
      created_at
      description
      id
      name_activity
      status
      owner {
        id
        displayName
      }
      # area_module {
      #   id
      #   area {
      #     description
      #     id
      #   }
      # }
      risk_treatment_activities {
        id_treatment_activity
        risk {
          initial_risk
          risk_monitoring(limit: 1, order_by: { created_at: desc }) {
            residual_risk
          }
        }
      }
    }
  }
`;

export const UPDATE_INVENTORY_BY_ID = gql`
  mutation UpdateInventory(
    $id: Int!
    $obj_inventory: data_mapping_treatment_activity_set_input!
  ) {
    update_data_mapping_treatment_activity_by_pk(
      pk_columns: { id: $id }
      _set: $obj_inventory
    ) {
      id
      status
    }
  }
`;

export const GET_INVENTORY_BY_FILTER = gql`
  query getInventoryByFilter(
    $where: data_mapping_treatment_activity_bool_exp!
  ) {
    data_mapping_treatment_activity(where: $where) {
      id
      name_activity
      created_at
      status
      area_modules {
        area {
          description
        }
        id_module
      }
      profile_holders {
        id
        id_combo_data_profile_holders
      }
      location_holders {
        id
        id_combo_data_location_holders
      }
      data_types {
        id
        id_combo_data_data_types
      }
      assessments {
        id
        id_combo_data_risk
      }
      owner {
        displayName
        id
      }
      risk_treatment_activities {
        id_treatment_activity
        risk {
          initial_risk
          risk_monitoring(limit: 1, order_by: { created_at: desc }) {
            residual_risk
          }
        }
      }
    }
  }
`;
